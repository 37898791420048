import * as React from "react"
import { graphql, Link } from 'gatsby'

const BankPage = ({data}) => {

    const bank = data.bank

    return (
        <main>
            <h1>{bank.title} boliglån</h1>
            <p>
                <strong>{bank.title}</strong>: {bank.intro}
            </p>

            <a rel="noopener nofollow" href={bank.url}>Søk boliglån i {bank.title}</a>

            <h2>Alternativer til boliglån fra {bank.title}:</h2>
            <p>Her er alternative tilbydere til boliglån fra {bank.title} som tilbyr boliglån til privatpersoner i Norge:</p>
            <ul>
                {data.altBanks.edges.map(({node},i) => {
                    if(node.slug.current != bank.slug.current){
                        return(
                            <li key={i}><Link to={'/'+node.slug.current}>{node.title}</Link></li>
                        )
                    }
                })}
            </ul>
        </main>
    )
}

export default BankPage


export const query = graphql`
  query BankPageQuery($slug: String!) {
    bank: sanityBank(slug: {current: {eq: $slug}}){
      title
      slug{
        current
      }
      intro
      url
    }
    
    altBanks: allSanityBank(filter: { slug: { current: { ne: null } } }, sort: {order: ASC, fields: slug___current}) {
        edges {
          node{
            slug{
                current
            }
            title
            intro
            url
          }
        }
      }
  }
`